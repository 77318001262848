import { Exclude } from 'class-transformer';
import { intToDate, isNullOrUndefined } from '../library/utils';
import { DatePipe } from '@angular/common';

export class EFacturaClientConfig {
	public lastRefreshMoment: string;
	public automaticInvoiceSubmitAfterNumDays: number;
	public isAutomaticInvoiceSubmitEnabled: boolean;

	/*   

	@Exclude()
	private _lastRefreshMomentEx: Date;
	@Exclude()
	public get lastRefreshMomentEx(): Date {
		if (!this._lastRefreshMomentEx && this.lastRefreshMoment) {
			this._lastRefreshMomentEx = new Date(this.lastRefreshMoment);
		}
		return this._lastRefreshMomentEx;
	}
 */
}
export class EFacturaModel {
	clientConfig: EFacturaClientConfig;
	lastRefreshMomentAsDate: Date = null;

	initialize(data: EFacturaClientConfig): void {
		if (data !== null) {
			this.clientConfig = data;
			this.lastRefreshMomentAsDate = isNullOrUndefined(data.lastRefreshMoment) ? null : new Date(data.lastRefreshMoment);
		}
	}
}

export class EFacturaStatisticsModel {
	public outboxWithErrorCount: number;
	public inboxNotVerifiedCount: number;
}

export enum EFacturaInboxStatus {
	New = 'New',
	Uploaded = 'Uploaded',
	Rejected = 'Rejected',
	Ignored = 'Ignored',
}

export class EFacturaInboxModel {
	public externalId: string;
	public clientEid: string;
	public clientName: string;
	public status: string;
	public documentType: string;
	public issueDate: number;
	public amount: number;
	public momentCreated: string;
	public serialNo: string;
	public partnerCif: string;
	public partnerName: string;
	public originalFileEid: string;
	public readableFileEid: string;
	public documentEid: string;
	public lastStatusChangeDateTime: string;
	public lastStatusChangeUserEid: string;
	public lastStatusChangeUserName: string;
	public dueDate: number;
	public timeUploaded: string;
	public indexDescarcare: string;
	public indexIncarcare: string;
	public isProtected: boolean;
	public messageOutbox: EFacturaOutboxNotificationMessageModel[];

	@Exclude()
	private _issueDateEx: Date;
	@Exclude()
	public get issueDateEx(): Date {
		if (!this._issueDateEx && this.issueDate) {
			this._issueDateEx = intToDate(this.issueDate);
		}
		return this._issueDateEx;
	}

	@Exclude()
	private _dueDateEx: Date;
	@Exclude()
	public get dueDateEx(): Date {
		if (!this._dueDateEx && this.dueDate) {
			this._dueDateEx = intToDate(this.dueDate);
		}
		return this._dueDateEx;
	}

	@Exclude()
	private _timeUploadedEx: Date;
	@Exclude()
	public get timeUploadedEx() {
		if (!this._timeUploadedEx && this.timeUploaded) {
			let year = parseInt(this.timeUploaded.substring(0, 4)),
				month = parseInt(this.timeUploaded.substring(4, 6)),
				day = parseInt(this.timeUploaded.substring(6, 8)),
				hour = this.timeUploaded.length == 12 ? parseInt(this.timeUploaded.substring(8, 10)) : parseInt(this.timeUploaded.substring(8, 9)),
				minutes = this.timeUploaded.length == 12 ? parseInt(this.timeUploaded.substring(10, 12)) : parseInt(this.timeUploaded.substring(9, 11));

			this._timeUploadedEx = new Date(year, month - 1, day, hour, minutes);
		}
		return this._timeUploadedEx;
	}

	@Exclude()
	public documentStatus: string;
}

export class EFacturaInboxListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public pageSize: number;
	public data: EFacturaInboxModel[];

	public get totalValoare(): number {
		let total = 0;
		this.data.forEach((d) => (total += d.amount ?? 0));
		if (total === 0) total = null;
		return total;
	}
}

export class EFacturaInboxHistoryListModel {
	public data: EFacturaInboxHistoryModel[];
}

export class EFacturaInboxHistoryModel {
	public externalId: string;
	public description: string;
	public performedDateTime: string;
	public performedUserEid: string;
	public performedUserName: string;

	@Exclude()
	get performedDateTimeFormattedString(): string {
		return new DatePipe('ro-RO').transform(this.performedDateTime, 'dd.MM.yyyy HH:mm:ss');
	}
}

export class EFacturaOutboxNotificationMessageModel {
	public clientEid: string;
	public clientName: string;
	public externalId: string;
	public indexDescarcare: string;
	public indexIncarcare: string;
	public message: string;
	public momentCreated: string;
	public momentUploaded: string;
	public originalFileEid: string;
	public partnerCif: string;
	public partnerName: string;
	public userEid: string;
	public userName: string;

	@Exclude()
	get momentCreatedFormattedString(): string {
		return new DatePipe('ro-RO').transform(this.momentCreated, 'dd.MM.yyyy HH:mm:ss');
	}

	@Exclude()
	get momentCreatedAsDate(): Date {
		return new Date(Date.parse(this.momentCreated));
	}
}

export class ClientDocumentStatusDataModel {
	public statusCode: string;
}

export class ClientDocumentDataModel {
	public eid: string;
	public clientEid: string;
	public status: ClientDocumentStatusDataModel;
}

export enum EFacturaOutboxStatus {
	Idle = 'Idle',
	RequestPosted = 'RequestPosted',
	RequestFinalizedOk = 'RequestFinalizedOk',
	RequestFinalizedWithErrors = 'RequestFinalizedWithErrors',
	Canceled = 'Canceled',
	InDepunere = 'InDepunere', // concat Idle + RequestPosted
}

export class EFacturaOutboxModel {
	public externalId: string;
	public invoiceEid: string;
	public clientEid: string;
	public clientName: string;
	public sysEFacturaDocumentType: string;
	public sysEFacturaOutboxStatus: string;
	public amount: number;
	public issueDate: number;
	public dueDate: number;
	public originalFileEid: string;
	public errors: string;
	public indexDescarcare: string;
	public indexIncarcare: string;
	public invoiceVersion: number;
	public isCorrection: boolean;
	public momentCreated: string;
	public partnerCif: string;
	public partnerName: string;
	public serialNo: string;
	public timeUploaded: string;
	public isIgnored: boolean;
	public hasNotReadMessages: boolean;
	public hasMessages: boolean;
	public hasTechnicalError: boolean;
	public messageInbox: EFacturaInboxNotificationMessageModel[];

	@Exclude()
	private _issueDateEx: Date;
	@Exclude()
	public get issueDateEx(): Date {
		if (!this._issueDateEx && this.issueDate) {
			this._issueDateEx = intToDate(this.issueDate);
		}
		return this._issueDateEx;
	}

	@Exclude()
	private _dueDateEx: Date;
	@Exclude()
	public get dueDateEx(): Date {
		if (!this._dueDateEx && this.dueDate) {
			this._dueDateEx = intToDate(this.dueDate);
		}
		return this._dueDateEx;
	}

	@Exclude()
	private _timeUploadedEx: Date;
	@Exclude()
	public get timeUploadedEx() {
		if (!this._timeUploadedEx && this.timeUploaded) {
			let year = parseInt(this.timeUploaded.substring(0, 4)),
				month = parseInt(this.timeUploaded.substring(4, 6)),
				day = parseInt(this.timeUploaded.substring(6, 8)),
				hour = this.timeUploaded.length == 12 ? parseInt(this.timeUploaded.substring(8, 10)) : parseInt(this.timeUploaded.substring(8, 9)),
				minutes = this.timeUploaded.length == 12 ? parseInt(this.timeUploaded.substring(10, 12)) : parseInt(this.timeUploaded.substring(9, 11));

			this._timeUploadedEx = new Date(year, month - 1, day, hour, minutes);
		}
		return this._timeUploadedEx;
	}

	@Exclude()
	public errorFromGrid: string;
}

export class EFacturaOutboxListModel {
	public recordsCount: number;
	public first: number;
	public last: number;
	public pageSize: number;
	public data: EFacturaOutboxModel[];

	public get totalValoare(): number {
		let total = 0;
		this.data.forEach((d) => (total += d.amount ?? 0));
		if (total === 0) total = null;
		return total;
	}
}

export class EFacturaInboxNotificationMessageModel {
	public clientEid: string;
	public clientName: string;
	public externalId: string;
	public indexDescarcare: string;
	public indexIncarcare: string;
	public message: string;
	public momentCreated: string;
	public momentUploaded: string;
	public originalFileEid: string;
	public partnerCif: string;
	public partnerName: string;
	public userEid: string;
	public userName: string;
	public isRead: boolean;

	@Exclude()
	get momentCreatedFormattedString(): string {
		return new DatePipe('ro-RO').transform(this.momentCreated, 'dd.MM.yyyy HH:mm:ss');
	}

	@Exclude()
	get momentCreatedAsDate(): Date {
		return new Date(Date.parse(this.momentCreated));
	}
}
