import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import gql from 'graphql-tag';
import { plainToClass, classToPlain } from 'class-transformer';

import { dateToInt, intToDate } from '@saliente/library';

import { RestService } from '../general/rest.service';
import {
	AccountingPeriodModel,
	AccountingPeriodCheckModel,
	AccountingPeriodType,
	AccountingPeriodTaskDefinitionModel,
	CloseActionUsingPostMutationVariables,
	Accounts_RunAmortizationsUsingPostMutation,
	Accounts_RunRevaluationsUsingPostMutation,
	Accounts_RunDefferalsUsingPostMutation as Accounts_RunDeferralsUsingPostMutation,
	OpenActionUsingPostMutationVariables,
	Accounts_OpenVATUsingPostMutation,
	Accounts_CloseVATUsingPostMutation,
	Accounts_OpenPNLUsingPostMutation,
	Accounts_ClosePNLUsingPostMutation,
	IgnoreVatUsingPutMutationVariables,
	Accounts_IgnoreVatUsingPutMutation,
	CloseVATActionUsingPostMutationVariables,
	Accounts_RunIncomeTaxUsingPostMutation,
	AccountingPeriodActionsStatusRepresentation,
	DeleteActionUsingDeleteVariables,
	Accounts_DeleteAmortizationsUsingDeleteMutation,
	Accounts_DeleteDefferalsUsingDeleteMutation,
	Accounts_DeleteRevaluationsUsingDeleteMutation,
	Accounts_DeleteIncomeTaxUsingDeleteMutation,
	Accounts_DeleteCleanUpUsingDeleteMutation,
	Accounts_RunCleanUpUsingPostMutation,
  Accounts_PublishTaxesToPayUsingPostMutationVariables,
  Accounts_PublishTaxesToPayUsingPostMutation,
} from './accounting-period.models';
import { ApolloResult } from '../../common/general/gql-codegen.types';

const periodCheckInstanceFields = `
	externalId
	isChecked
  isIgnored
	comments
	lastCheckResult
	lastCheckedBy
	accountingPeriodCheckDefinition {
		externalId
		checkCode
		checkType
		description
		displayOrder
		isActive
	}
`;

const actionsStatusFields = `
  bypassCanNotChangeIgnoreVat
  bypassCanNotChangeIgnoreVatDisabled
  canChangeIgnoreVAT
  canChangePNL
  canChangeVAT
  canRunRecordings
  isPNLOpen
  isVATOpen
  lastRunAmortizationsSuccess
  lastRunDeferralsSuccess
  lastRunIncomeTaxSuccess
  lastRunRevaluationsSuccess
  lastRunCleanUpSuccess
  monthOpenInCiel
  proposedVATForDateStart
  vatIgnored
  decisionCaseId
  prevVATOpen
  nextVATOpen
  prevPNLOpen
  nextPNLOpen
  canPublishTaxesToPay
  monthClosings {
    monthClosings {
      closedOn
      comments
      isClosed
      monthEnd
      monthStart
      turnover
      type
    }
  }
`;

const accountingPeriodQueryText = gql`
query accountingPeriod($clientEid: String, $accountingPeriodType:String, $forDate:Int) {
	accounts {
		getAccountingPeriodUsingGET(clientEid:$clientEid, accountingPeriodType:$accountingPeriodType, forDate:$forDate) {
			accountingPeriodType
			canChangeStatus
			forDate
			isOpen

			accountingPeriodCheckInstanceRepresentations{
				${periodCheckInstanceFields}
			}

			accountingPeriodTaskInstanceRepresentations	{
				externalId
				isPerformed
				comments
				accountingPeriodTaskDefinition{
					externalId
					description
					displayOrder
					isActive
				}
			}
      actionsStatus {
        ${actionsStatusFields}
      }
      vatComment
		}
	}
}`;

const lastClosedAccountingPeriodQueryText = gql`
	query lastClosedAccountingPeriod($clientEid: String, $accountingPeriodType: String) {
		accounts {
			getLastClosedAccountingPeriodUsingGET(clientEid: $clientEid, accountingPeriodType: $accountingPeriodType) {
				period
			}
		}
	}
`;

// const toggleStatusMutationText = gql`
// mutation toggleStatus($clientEid: String, $accountingPeriodType:String, $forDate:Int){
//   accounts {
//     changeAccountingPeriodStatusUsingPATCH(clientEid:$clientEid, accountingPeriodType:$accountingPeriodType, forDate:$forDate) {
//       accountingPeriodType
// 		  canChangeStatus
// 		  forDate
// 		  isOpen
//     }
//   }
// }`;

const createPeriodCheckDefinitionMutationText = gql`
	mutation createPeriodCheckDefinition($accountingPeriodType: String, $data: accounts_AccountingPeriodCheckDefinitionRepresentationInput) {
		accounts {
			createAccountingPeriodCheckDefinitionUsingPOST(accountingPeriodType: $accountingPeriodType, representation: $data) {
				Message
			}
		}
	}
`;

const updatePeriodCheckDefinitionMutationText = gql`
	mutation updatePeriodCheckDefinition($externalId: String!, $accountingPeriodType: String, $data: accounts_AccountingPeriodCheckDefinitionRepresentationInput) {
		accounts {
			updateAccountingPeriodCheckDefinitionUsingPATCH(externalId: $externalId, accountingPeriodType: $accountingPeriodType, representation: $data) {
				Message
			}
		}
	}
`;

const updatePeriodCheckInstanceMutationText = gql`
	mutation periodCheck($clientEid: String, $accountingPeriodType: String, $externalId: String!, $data: accounts_AccountingPeriodCheckInstanceRepresentation4UpdateInput) {
		accounts {
			updateAccountingPeriodCheckInstanceUsingPATCH(clientEid: $clientEid, accountingPeriodType: $accountingPeriodType, externalId: $externalId, representation: $data) {
				Message
			}
		}
	}
`;

const performCheckMutationText = gql`
mutation performCheck($clientEid: String, $accountingPeriodType:String, $externalId:String!){
  accounts {
    performCheckUsingPOST(clientEid:$clientEid, accountingPeriodType:$accountingPeriodType, externalId:$externalId) {
		${periodCheckInstanceFields}
    }
  }
}`;

const performAllChecksMutationText = gql`
mutation performAllChecks($clientEid: String, $accountingPeriodType:String, $forDate:Int){
  accounts {
    performAllChecksUsingPOST(clientEid:$clientEid, accountingPeriodType:$accountingPeriodType, forYYYYMM01:$forDate) {
		${periodCheckInstanceFields}
    }
  }
}`;

const createPeriodTaskDefinitionMutationText = gql`
	mutation createPeriodTaskDefinition($clientEid: String, $accountingPeriodType: String, $starDate: Int, $data: accounts_AccountingPeriodTaskDefinitionRepresentationInput) {
		accounts {
			createAccountingPeriodTaskDefinitionStartingWithUsingPOST(clientEid: $clientEid, accountingPeriodType: $accountingPeriodType, monthYYYYMM: $starDate, representation: $data) {
				Message
			}
		}
	}
`;

const updatePeriodTaskDefinitionMutationText = gql`
	mutation updatePeriodTaskDefinition($clientEid: String, $externalId: String!, $accountingPeriodType: String, $data: accounts_AccountingPeriodTaskDefinitionRepresentationInput) {
		accounts {
			updateAccountingPeriodTaskDefinitionUsingPATCH(clientEid: $clientEid, externalId: $externalId, accountingPeriodType: $accountingPeriodType, representation: $data) {
				Message
			}
		}
	}
`;

const performTaskMutationText = gql`
	mutation performTask($clientEid: String, $accountingPeriodType: String, $externalId: String!, $data: accounts_AccountingPeriodTaskInstanceRepresentation4UpdateInput) {
		accounts {
			updateAccountingPeriodTaskInstanceUsingPATCH(clientEid: $clientEid, accountingPeriodType: $accountingPeriodType, externalId: $externalId, representation: $data) {
				Message
			}
		}
	}
`;

export const Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutDocument = gql`
    mutation accounts_toogleBypassCanNotChangeIgnoreVatUsingPUT(
        $clientEid: String
        $forDate: Int
    ) {
        accounts {
            toogleBypassCanNotChangeIgnoreVatUsingPUT(
                clientEid: $clientEid
                forDate: $forDate
            ) {
              ${actionsStatusFields}
            }
        }
    }
`;
export type Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutMutationVariables = {
	clientEid?: string;
	forDate?: number;
};

export type Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutMutation = {
	accounts?: {
		toogleBypassCanNotChangeIgnoreVatUsingPUT?: AccountingPeriodActionsStatusRepresentation;
	};
};

@Injectable()
export class AccountingPeriodService {
	private accountingPeriodDefinitionVersion = new BehaviorSubject<number>(0);

	constructor(private restService: RestService) {}

	public accountingPeriodStream(clientEid: string, forDate: Date): Observable<AccountingPeriodModel> {
		return this.accountingPeriodDefinitionVersion.pipe(
			switchMap(() =>
				this.restService
					.query({
						query: accountingPeriodQueryText,
						fetchPolicy: 'network-only',
						variables: {
							clientEid,
							accountingPeriodType: AccountingPeriodType.Accounting,
							forDate: dateToInt(forDate),
						},
					})
					.pipe(
						map((response: any) => {
							if (response) {
								const result = plainToClass(AccountingPeriodModel, response.data.accounts.getAccountingPeriodUsingGET);
								result.checks = this.sortCheckInstances(result.checks);
								return result;
							}
							return null;
						})
					)
			)
		);
	}

	public accountingPeriodDefinitionChanged(): void {
		this.accountingPeriodDefinitionVersion.next(this.accountingPeriodDefinitionVersion.value + 1);
	}

	public accountingFirstOpenedDayStream(clientEid: string): Observable<any> {
		return this.restService
			.query(
				{
					query: lastClosedAccountingPeriodQueryText,
					fetchPolicy: 'network-only',
					variables: {
						clientEid,
						accountingPeriodType: AccountingPeriodType.Accounting,
					},
				},
				{ error: false }
			)
			.pipe(
				map((response: any) => {
					if (response) {
						//let result = intToDate(response.data.accounts.getLastClosedAccountingPeriodUsingGET.forDate);
						let result = this.getFirstDateAfterLastClosedAccountingPeriod(response.data.accounts.getLastClosedAccountingPeriodUsingGET.period);

						if (!isNaN(result.getTime())) {
							result.setMonth(result.getMonth()); //+ 1
							return result;
						}
					}
					return null;
				}),
				catchError((error) => {
					return of(null);
				})
			);
	}

	public getFirstDateAfterLastClosedAccountingPeriod(period: number): Date {
		var firstDayOfThePeriod = intToDate(period * 100 + 1);
		return new Date(firstDayOfThePeriod.getFullYear(), firstDayOfThePeriod.getMonth() + 1, 1);
	}

	// public accountingPeriodToggleStatusStream(clientEid: string, forDate: Date) {
	//   return this.restService
	//     .mutate({
	//       mutation: toggleStatusMutationText,
	//       variables: {
	//         clientEid,
	//         accountingPeriodType: AccountingPeriodType.Accounting,
	//         forDate: dateToInt(forDate)
	//       }
	//     })
	//     .pipe(
	//       map((response: any) => {
	//         if (response) {
	//           return plainToClass(AccountingPeriodModel, response.data.accounts.changeAccountingPeriodStatusUsingPATCH);
	//         }
	//         return null;
	//       })
	//     );
	// }

	public accountingCreateCheckStream(data: any) {
		return this.restService
			.mutate({
				mutation: createPeriodCheckDefinitionMutationText,
				variables: {
					accountingPeriodType: AccountingPeriodType.Accounting,
					data: classToPlain(data, { excludePrefixes: ['__'] }),
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public accountingUpdateCheckDefinitionStream(data: any) {
		return this.restService
			.mutate({
				mutation: updatePeriodCheckDefinitionMutationText,
				variables: {
					accountingPeriodType: AccountingPeriodType.Accounting,
					externalId: data.id,
					data: classToPlain(data, { excludePrefixes: ['__'] }),
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public accountingUpdateCheckInstanceStream(clientEid: string, periodId: string, data: any) {
		return this.restService
			.mutate({
				mutation: updatePeriodCheckInstanceMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					externalId: periodId,
					data,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public accountingPerformCheckStream(clientEid: string, checkId: string) {
		return this.restService
			.mutate({
				mutation: performCheckMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					externalId: checkId,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return plainToClass(AccountingPeriodCheckModel, response.data.accounts.performCheckUsingPOST);
					}
					return null;
				})
			);
	}

	private sortCheckInstances(checkInstances: AccountingPeriodCheckModel[]) {
		return checkInstances.slice(0).sort((a: AccountingPeriodCheckModel, b: AccountingPeriodCheckModel) => {
			return (a.definition.displayOrder || 5000) - (b.definition.displayOrder || 5000);
		});
	}

	public accountingPerformAllChecksStream(clientEid: string, forDate: Date) {
		return this.restService
			.mutate({
				mutation: performAllChecksMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					forDate: dateToInt(forDate),
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return this.sortCheckInstances(plainToClass<AccountingPeriodCheckModel, object>(AccountingPeriodCheckModel, response.data.accounts.performAllChecksUsingPOST));
					}
					return null;
				})
			);
	}

	public accountingCreateTaskStream(clientEid: string, data: AccountingPeriodTaskDefinitionModel) {
		return this.restService
			.mutate({
				mutation: createPeriodTaskDefinitionMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					starDate: Math.floor(dateToInt(data.startDate) / 100),
					data: classToPlain(data, { excludePrefixes: ['__'] }),
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public accountingUpdateTaskStream(clientEid: string, data: any) {
		return this.restService
			.mutate({
				mutation: updatePeriodTaskDefinitionMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					externalId: data.id,
					data: classToPlain(data, { excludePrefixes: ['__'] }),
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}

	public accountingPerformTaskStream(clientEid: string, periodId: string, data: any) {
		return this.restService
			.mutate({
				mutation: performTaskMutationText,
				variables: {
					clientEid,
					accountingPeriodType: AccountingPeriodType.Accounting,
					externalId: periodId,
					data,
				},
			})
			.pipe(
				map((response: any) => {
					if (response) {
						return true;
					}
					return false;
				})
			);
	}
	/*-------------------------------common fields--------------------------*/
	actionResponseFields = `
  actionsStatus {
    ${actionsStatusFields}
  }
  resetChecksIgnoredStatus
  insertedNotesResponse {
    accountingNoteInfos {
      Amount
      Comment
      Credit
      Debit
      Number
    }
    response {
      Errors
      InsertedIds
      ResultCode
      jsonResponse
    }
 }
 deleteActionResponse {
  ResultCode
  Errors
  jsonResponse
}

 `;

	/*-----------------------------Amortizations------------------------*/
	RunAmortizationsUsingPostDocument = gql`
    mutation accounts_runAmortizationsUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
  accounts {
    runAmortizationsUsingPOST(clientEid: $clientEid,forDate: $forDate,isTestClosing: $isTestClosing
    ) {
      ${this.actionResponseFields}
   }
  }
}
`;
	public runAmortizations(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_RunAmortizationsUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.RunAmortizationsUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_RunAmortizationsUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	DeleteAmortizationsUsingDeleteDocument = gql`
    mutation accounts_deleteAmortizationsUsingDELETE($clientEid: String, $forDate: Int) {
  accounts {
    deleteAmortizationsUsingDELETE(clientEid: $clientEid,forDate: $forDate
    ) {
      ${this.actionResponseFields}
   }
  }
}
`;
	public deleteAmortizations(variables: DeleteActionUsingDeleteVariables): Observable<Accounts_DeleteAmortizationsUsingDeleteMutation> {
		return this.restService
			.mutate({
				mutation: this.DeleteAmortizationsUsingDeleteDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_DeleteAmortizationsUsingDeleteMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	/*-----------------------------Revaluations------------------------*/
	RunRevaluationsUsingPostDocument = gql`
    mutation accounts_runRevaluationsUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
  accounts {
    runRevaluationsUsingPOST(clientEid: $clientEid,forDate: $forDate,isTestClosing: $isTestClosing
    ) {
      ${this.actionResponseFields}
    }
  }
}
`;

	public runRevaluations(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_RunRevaluationsUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.RunRevaluationsUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_RunRevaluationsUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	DeleteRevaluationsUsingDeleteDocument = gql`
  mutation accounts_deleteRevaluationsUsingDELETE($clientEid: String, $forDate: Int) {
    accounts {
      deleteRevaluationsUsingDELETE(clientEid: $clientEid,forDate: $forDate) {
        ${this.actionResponseFields}
      }
  } 
  }`;

	public deleteRevaluations(variables: DeleteActionUsingDeleteVariables): Observable<Accounts_DeleteRevaluationsUsingDeleteMutation> {
		return this.restService
			.mutate({
				mutation: this.DeleteRevaluationsUsingDeleteDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_DeleteRevaluationsUsingDeleteMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	/*-----------------------------Deferrals------------------------*/
	RunDeferralsUsingPostDocument = gql`
    mutation accounts_runDeferralsUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
  accounts {
    runDeferralsUsingPOST(clientEid: $clientEid,forDate: $forDate,isTestClosing: $isTestClosing) {
      ${this.actionResponseFields}
    }
  }
}`;

	public runDeferrals(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_RunDeferralsUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.RunDeferralsUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_RunDeferralsUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	DeleteDeferralsUsingDeleteDocument = gql`
  mutation accounts_deleteDeferralsUsingDELETE($clientEid: String, $forDate: Int) {
accounts {
  deleteDeferralsUsingDELETE(clientEid: $clientEid,forDate: $forDate) {
    ${this.actionResponseFields}
  }
}
}`;

	public deleteDeferrals(variables: DeleteActionUsingDeleteVariables): Observable<Accounts_DeleteDefferalsUsingDeleteMutation> {
		return this.restService
			.mutate({
				mutation: this.DeleteDeferralsUsingDeleteDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_DeleteDefferalsUsingDeleteMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	/*-----------------------------IncomeTax------------------------*/
	RunIncomeTaxUsingPostDocument = gql`
  mutation accounts_runIncomeTaxUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
accounts {
  runIncomeTaxUsingPOST(clientEid: $clientEid,forDate: $forDate,isTestClosing: $isTestClosing) {
    ${this.actionResponseFields}
  }
}
}`;

	public runIncomeTax(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_RunIncomeTaxUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.RunIncomeTaxUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_RunIncomeTaxUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	DeleteIncomeTaxUsingDeleteDocument = gql`
  mutation accounts_deleteRevaluationsUsingDELETE($clientEid: String, $forDate: Int) {
    accounts {
      deleteIncomeTaxUsingDELETE(clientEid: $clientEid,forDate: $forDate) {
        ${this.actionResponseFields}
      }
  } 
  }`;

	public deleteIncomeTax(variables: DeleteActionUsingDeleteVariables): Observable<Accounts_DeleteIncomeTaxUsingDeleteMutation> {
		return this.restService
			.mutate({
				mutation: this.DeleteIncomeTaxUsingDeleteDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_DeleteIncomeTaxUsingDeleteMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	/*-----------------------------CleanUp------------------------*/

	RunCleanUpUsingPostDocument = gql`
  mutation accounts_runCleanUpUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
accounts {
  runCleanUpUsingPOST(clientEid: $clientEid,forDate: $forDate,isTestClosing: $isTestClosing) {
    ${this.actionResponseFields}
  }
}
}`;

	public runCleanUp(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_RunCleanUpUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.RunCleanUpUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_RunCleanUpUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	DeleteCleanUpUsingDeleteDocument = gql`
  mutation accounts_deleteCleanUpUsingDELETE($clientEid: String, $forDate: Int) {
    accounts {
      deleteCleanUpUsingDELETE(clientEid: $clientEid,forDate: $forDate) {
        ${this.actionResponseFields}
      }
  } 
  }`;

	public deleteCleanUp(variables: DeleteActionUsingDeleteVariables): Observable<Accounts_DeleteCleanUpUsingDeleteMutation> {
		return this.restService
			.mutate({
				mutation: this.DeleteCleanUpUsingDeleteDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_DeleteCleanUpUsingDeleteMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}

	/*----------------------------publishTaxesToPay---------------*/
	Accounts_PublishTaxesToPayUsingPostDocument = gql`
  mutation accounts_publishTaxesToPayUsingPOST($clientEid: String, $forDate: Int) {
accounts {
  publishTaxesToPayUsingPOST(clientEid: $clientEid, forDate: $forDate) {        ${this.actionResponseFields}
      }
  } 
  }`;

	public publishTaxesToPay(variables: Accounts_PublishTaxesToPayUsingPostMutationVariables): Observable<Accounts_PublishTaxesToPayUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.Accounts_PublishTaxesToPayUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_PublishTaxesToPayUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	/*-----------------------------openVAT------------------------*/
	OpenVatUsingPostDocument = gql`
    mutation accounts_openVATUsingPOST($clientEid: String, $forDate: Int) {
  accounts {
    openVATUsingPOST(clientEid: $clientEid, forDate: $forDate) {
      ${this.actionResponseFields}
    }
  }
}
`;

	public openVAT(variables: OpenActionUsingPostMutationVariables): Observable<Accounts_OpenVATUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.OpenVatUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_OpenVATUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	/*-----------------------------closeVAT------------------------*/
	CloseVATUsingPostDocument = gql`
    mutation accounts_closeVATUsingPOST($clientEid: String, $forDateStart: Int,$forDate: Int, $isTestClosing: Boolean) {
  accounts {
    closeVATUsingPOST(clientEid: $clientEid,forDateStart: $forDateStart ,forDate: $forDate ,isTestClosing: $isTestClosing) {
      ${this.actionResponseFields}
    }
  }
}
`;

	public closeVAT(variables: CloseVATActionUsingPostMutationVariables): Observable<Accounts_CloseVATUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.CloseVATUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_CloseVATUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	/*-----------------------------openPNL------------------------*/
	OpenPnlUsingPostDocument = gql`
    mutation accounts_openPNLUsingPOST($clientEid: String, $forDate: Int) {
  accounts {
    openPNLUsingPOST(clientEid: $clientEid, forDate: $forDate) {
      ${this.actionResponseFields}
    }
  }
}
`;

	public openPNL(variables: OpenActionUsingPostMutationVariables): Observable<Accounts_OpenPNLUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.OpenPnlUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_OpenPNLUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	/*-----------------------------closePNL------------------------*/
	ClosePNLUsingPostDocument = gql`
    mutation accounts_closePNLUsingPOST($clientEid: String, $forDate: Int, $isTestClosing: Boolean) {
  accounts {
    closePNLUsingPOST(
      clientEid: $clientEid
      forDate: $forDate
      isTestClosing: $isTestClosing
    ) {
      ${this.actionResponseFields}
    }
  }
}
`;

	public closePNL(variables: CloseActionUsingPostMutationVariables): Observable<Accounts_ClosePNLUsingPostMutation> {
		return this.restService
			.mutate({
				mutation: this.ClosePNLUsingPostDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_ClosePNLUsingPostMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	/*-----------------------------IgnoreVAT------------------------*/
	IgnoreVatUsingPutDocument = gql`
		mutation accounts_ignoreVATUsingPUT($clientEid: String, $forDate: Int, $ignore: Boolean) {
			accounts {
				ignoreVATUsingPUT(clientEid: $clientEid, forDate: $forDate, ignore: $ignore) {
	        ${actionsStatusFields}				
        }
			}
		}
	`;

	public ignoreVAT(variables: IgnoreVatUsingPutMutationVariables): Observable<Accounts_IgnoreVatUsingPutMutation> {
		return this.restService
			.mutate({
				mutation: this.IgnoreVatUsingPutDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_IgnoreVatUsingPutMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
	public toogleBypassCanNotChangeIgnoreVat(
		variables: Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutMutationVariables
	): Observable<Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutMutation> {
		return this.restService
			.mutate({
				mutation: Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutDocument,
				variables: variables,
			})
			.pipe(
				map((res: ApolloResult<Accounts_ToogleBypassCanNotChangeIgnoreVatUsingPutMutation>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
}
