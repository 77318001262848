import { Type, Exclude } from 'class-transformer';
import { getRandomInt } from '@saliente/library';

export class ClientFinancialReportingTypesProvider {
	static date() {
		return Date;
	}
}

export class ClientFinancialReportDetailActionsModel {
	public code: string;
	public description: string;

	public confirmationMessage: string;
	public role: string;
	public isClientRole: string;
	public buttonClass: string;
	public refreshOnSuccess: Boolean;
	public render: string;

	static typeProvider() {
		return ClientFinancialReportDetailActionsModel;
	}
}

export class ClientFinancialReportDetailFieldsModel {
	public name: string;
	public type: string;
	public title: string;

	public align: string = 'left';
	public headerAlign: string = 'left';
	public format: string = '';
	public width: number;
	public link: string = '';
	public dataLink: string = '';
	public reportLink: any;

	public aggregate: string;
	public visible: boolean = true;	

	public actions: ClientFinancialReportDetailActionsModel[] = [];

	get cellStyle(): any {
		return { 'text-align': this.align, 'min-width': '50px' };
	}

	get headerStyle(): any {
		return { 'text-align': this.headerAlign };
	}

	static typeProvider() {
		return ClientFinancialReportDetailFieldsModel;
	}
}

export class ClientFinancialReportDateFilterConfig {
	@Type(ClientFinancialReportingTypesProvider.date)
	public default: Date;
	@Type(ClientFinancialReportingTypesProvider.date)
	public min: Date;
	@Type(ClientFinancialReportingTypesProvider.date)
	public max: Date;

	public placeholder: string;
	public formatPlaceholder: string;
	public format: string;
	public activeView: string;
	public weekNumber: string;
	public class: string;

	static typeProvider() {
		return ClientFinancialReportDateFilterConfig;
	}
}

export class ClientFinancialReportNumericFilterConfig {
	public default: number;
	public min: number;
	public max: number;

	public autoCorrect: boolean = false;
	public format: string;
	public class: string;

	static typeProvider() {
		return ClientFinancialReportNumericFilterConfig;
	}
}

export class ClientFinancialReportTextFilterConfig {
	public default: string;
	public class: string;

	static typeProvider() {
		return ClientFinancialReportTextFilterConfig;
	}
}

export class ClientFinancialReportSelectFilterConfig {
	public default: any;
	public values: any[];
	public textField: string;
	public valueField: string;

	public valuePrimitive: boolean = true;
	public class: string;

	static typeProvider() {
		return ClientFinancialReportSelectFilterConfig;
	}
}

export class ClientFinancialReportFilter {
	public name: string;
	public label: string;
	public type: string;

	@Type(ClientFinancialReportDateFilterConfig.typeProvider)
	public dateConfig: ClientFinancialReportDateFilterConfig;

	@Type(ClientFinancialReportNumericFilterConfig.typeProvider)
	public numericConfig: ClientFinancialReportNumericFilterConfig;

	@Type(ClientFinancialReportTextFilterConfig.typeProvider)
	public textConfig: ClientFinancialReportTextFilterConfig;

	@Type(ClientFinancialReportSelectFilterConfig.typeProvider)
	public selectConfig: ClientFinancialReportSelectFilterConfig;

	static typeProvider() {
		return ClientFinancialReportFilter;
	}
}

export class ClientFinancialReportSortDetail {
	public field: string;
	public dir: string;

	static typeProvider() {
		return ClientFinancialReportSortDetail;
	}
}

export class ClientFinancialReportGroupDetail {
	public field: string;
	public dir: string;

	static typeProvider() {
		return ClientFinancialReportGroupDetail;
	}
}

export class ClientFinancialReportDetail {
	public name: string;
	public currency: string;
	public amount: Number;
	public type: string;
	public link: string = '';
	public allowImport: boolean;
	public exportDbf: boolean;
	public collapsed: boolean;
	public rowClassField: string;
	public permission: string;
	public selectionKey: string;
	public refreshFilters: boolean = false;

	public hasActions: boolean = false;

	@Type(ClientFinancialReportDetailActionsModel.typeProvider)
	public actions: ClientFinancialReportDetailActionsModel[] = [];
	
	@Type(ClientFinancialReportDetailFieldsModel.typeProvider)
	public fields: ClientFinancialReportDetailFieldsModel[];

	@Type(ClientFinancialReportFilter.typeProvider)
	public filters: ClientFinancialReportFilter[];

	@Type(ClientFinancialReportGroupDetail.typeProvider)
	public groups: ClientFinancialReportGroupDetail[];

	@Type(ClientFinancialReportSortDetail.typeProvider)
	public sort: ClientFinancialReportSortDetail[];

	public values: any[];

	public tryConvertValues() {
		this.values = this.values || [];
		this.values.forEach((item: any) => {
			this.fields.forEach((field: ClientFinancialReportDetailFieldsModel) => {
				if (field.type === 'date') {
					if (item[field.name]) {
						item[field.name] = new Date(item[field.name]);
					}
				}
			});
		});
	}
}

export class ClientFinancialReportHeaderModel {
	public name: string;
	public type: string;
	public value: number;
	public currency: string;
	public icon: string;
	public backgroundColor: string;
	public textColor: string;
	public className: string;
	public permission: string;
	public barClassName: string;
	public displayWeb: boolean;

	@Exclude()
	private _sparkline: number[];
	@Exclude()
	get sparkline(): number[] {
		if (!this._sparkline) {
			this._sparkline = [];
			for (let index = 0; index < 12; index++) {
				this._sparkline.push(getRandomInt(10, 100));
			}
		}
		return this._sparkline;
	}

	static typeProvider(): typeof ClientFinancialReportHeaderModel {
		return ClientFinancialReportHeaderModel;
	}
}

export type Reports_ForcedRunByClientAndMonthUsingPostMutationVariables = {
	clientCode?: string;
	forMonthYYYYMM?: number;
};

export type Reports_ForcedRunByClientAndMonthUsingPostMutation = { reports?: { forcedRunByClientAndMonthUsingPOST?: { Message?: string } } };

export class MenuBadgeNumbersModel {
	public clarificationCount?: number;
	public cardClarificationCount?: number;
	public reconciliationCount?: number;
	public missingDocsCount: number;
}

export enum sysBatchResultEnum {
    FinishedOk = "FinishedOk",
    FinishedWithError = "FinishedWithError",
    FinishedWithCancel = "FinishedWithCancel",
}

export enum sysBatchStatusEnum {
    Started = "Started",
    Finished = "Finished",
}

